<template>

  <!-- Section Articles -->
  <ArticleTable
    heading="Articles"
    :articles="articles"
    :hide="['section']"
    date-label="Published"
    date-field="published_at"
    @paginate="handlePaginate"
    class="section-articles"
  >

    <!-- Table Actions -->
    <template #actions>

      <!-- Search Bar -->
      <j-search
        @search="handleSearch"
      />

    </template>
  </ArticleTable>
</template>

<script>

// Components
import ArticleTable from '@/components/widgets/articles/ArticleTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('sections')

export default {
  name: 'SectionArticles',
  // -------------
  // Components ==
  // -------------
  components: {
    ArticleTable
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // Vuex
    ...mapState([
      'articles'
    ]),
    // Route
    slug () {
      return this.$route.params.slug
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Vuex
    ...mapActions([
      'fetchArticles'
    ]),
    // Handlers
    handleSearch(query) {
      this.fetchArticles({
        slug: this.slug,
        query
      })
    },
    handlePaginate(page) {
      this.fetchArticles({
        slug: this.slug,
        query: this.articles.get('query'),
        page
      })
    }
  }
}
</script>
