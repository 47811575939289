<template>

  <!-- Section View Panel -->
  <div id="section-view-panel">

    <!-- Article Listing -->
    <SectionArticles />

  </div>    
</template>

<script>

// Components
import SectionArticles from '@/components/Sections/SectionArticles'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('sections')

export default {
  name: 'SectionViewPanel',
  // -------------
  // Components ==
  // -------------
  components: {
    SectionArticles
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const slug = to.params.slug
      if (slug !== from.params.slug) {
        vm.fetchArticles({ slug })
      }
    })
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Vuex
    ...mapActions([
      'fetchArticles'
    ])
  }
}
</script>
